import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-form"
export default class extends Controller {
  static targets = ["sortBySelect", "presenceSelect"];

  connect() {
    this.initializeSelect2();

    // Find all checkboxes within the form
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", this.handleCheckboxChange.bind(this));
    });
  }

  initializeSelect2() {
    if (this.hasSortBySelectTarget) {
      $(this.sortBySelectTarget).select2().on('select2:select', (e) => {
        this.sortBySelectTarget.dispatchEvent(new Event('change', { bubbles: true }));
      });
    }
    if (this.hasPresenceSelectTarget) {
      $(this.presenceSelectTarget).select2().on('select2:select', (e) => {
        this.presenceSelectTarget.dispatchEvent(new Event('change', { bubbles: true }));
      });
    }
  }

  handleCheckboxChange() {
    const form = this.element;

    let formData = new FormData(form);
    formData.delete("authenticity_token");
    let formDataUrl = new URLSearchParams(formData).toString();
    const urlWithoutParams = window.location.origin + window.location.pathname;
    const newFilteredUrl = `${urlWithoutParams}?${formDataUrl}`
    window.history.replaceState({}, document.title, newFilteredUrl);

    // Submit the form when any checkbox is checked
    form.requestSubmit();

    // Реініціалізуємо select2 після оновлення URL
    this.initializeSelect2();
  }

  change_sort_by() {
    const form = this.element;
    const selectedValue = this.sortBySelectTarget.value;

    // Update window URL with the new sort_by parameter
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("filter[sort_by]", selectedValue);

    // Remove the sort_by filter if the user selects "None"
    if (selectedValue == "") {
      currentUrl.searchParams.delete("filter[sort_by]");
    }

    // Redirect to the updated URL
    window.history.replaceState({}, document.title, currentUrl.href);

    // Submit the form when sort_by is selected
    form.requestSubmit();
  }

  change_presence() {
    const form = this.element;
    const selectedValue = this.presenceSelectTarget.value;

    // Update window URL with the new presence parameter
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("filter[presence]", selectedValue);

    // Remove the presence filter if the user selects "All"
    if (selectedValue == "") {
      currentUrl.searchParams.delete("filter[presence]");
    }

    // Redirect to the updated URL
    window.history.replaceState({}, document.title, currentUrl.href);

    // Submit the form when presence is selected
    form.requestSubmit();
  }
}
