var currentURL = window.location.href;

if (!currentURL.includes("/wedding-dress/") &&
    !currentURL.includes("/evening-dress/") &&
    !currentURL.includes("/ky-atelier/")    &&
    !currentURL.includes("/accessories/")  ) {
    function addSelect2Styles() {
        if (!document.getElementById('select2-style')) {
        const styleElement = document.createElement('style');
        styleElement.id = 'select2-style';
        styleElement.innerHTML = `
            .select2-search.select2-search--dropdown {
                display: none !important;
            }
            .select2-dropdown.select2-dropdown--below {
                width: 130px !important;
            }
        `;
        document.head.appendChild(styleElement);
        }
    }

    $(document).on('select2:open', function() {
        addSelect2Styles();
    });

    $(document).on('DOMNodeInserted', function(event) {
        if (event.target && event.target.classList.contains('select2-container')) {
        addSelect2Styles();
        }
    });
}
